import {FileMetadataBase, IFileMetadataBase} from './FileMetadataBase';

export class TempFileMetadata extends FileMetadataBase implements ITempFileMetadata {
  filePath?: string | undefined;

  constructor(data?: ITempFileMetadata) {
    super(data);
  }

  init(_data?: any) {
    super.init(_data);
    if (_data) {
      this.filePath = _data['filePath'];
    }
  }

  static fromJS(data: any): TempFileMetadata {
    data = typeof data === 'object' ? data : {};
    const result = new TempFileMetadata();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['filePath'] = this.filePath;
    super.toJSON(data);
    return data;
  }
}

export interface ITempFileMetadata extends IFileMetadataBase {
  filePath?: string | undefined;
}