import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {FileMetadata} from '../../types/models/files/FileMetadata';
import {FILE_CACHE} from './Actions';

export interface IFilesCacheState {
  fileCacheUpdating: boolean | false;
  fileCacheError: string | undefined;
  filePendingIdList: string[];
  fileCache: FileMetadata[];
}

const initialState: IFilesCacheState = {
  fileCacheUpdating: false,
  fileCacheError: undefined,
  filePendingIdList: [],
  fileCache: [],
}

const filesCacheReducer = createReducer(initialState, {
  [FILE_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.filePendingIdList = [...new Set([...state.filePendingIdList, ...action.payload])];
    state.fileCacheError = undefined;
  },
  [FILE_CACHE.UPDATE]: (state) => {
    state.filePendingIdList = [];
    state.fileCacheUpdating = true;
    state.fileCacheError = undefined;
  },
  [FILE_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<FileMetadata[]>) => {
    const currentFileCache = state.fileCache;
    action.payload.map(item => {
      if (!currentFileCache.find(x => x.fileId === item.fileId)) {
        currentFileCache.push(item);
      }
    });
    state.fileCache = currentFileCache;
    state.fileCacheUpdating = false;
    state.fileCacheError = undefined;
  },
  [FILE_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.fileCache = state.fileCache.filter(x => x.fileId !== action.payload);
    state.fileCacheUpdating = false;
    state.fileCacheError = undefined;
  },
});

export default filesCacheReducer;