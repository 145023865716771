import React from 'react';
import defaultConfig from './defaultConfig';
import AppContext from '../AppContext';

export const layoutState = {
  theme: defaultConfig.theme,
  footer: defaultConfig.footer,
  footerType: defaultConfig.footerType,
  themeMode: defaultConfig.themeMode,
  headerMode: defaultConfig.headerMode,
  themeStyle: defaultConfig.themeStyle,
  layoutType: defaultConfig.layoutType,
  isRTL: defaultConfig.theme.direction === 'rtl',
  locale: defaultConfig.locale,
  navStyle: defaultConfig.navStyle,
  rtAnim: defaultConfig.rtAnim,
  primary: defaultConfig.theme.palette.primary.main,
  sidebarColor: defaultConfig.theme.palette.sidebar.bgColor,
  secondary: defaultConfig.theme.palette.secondary.main,
};
interface IContextProvider {
  children: React.ReactNode;
}
const ContextProvider: React.FC<IContextProvider> = ({children}) => {


  return (
    <AppContext.Provider
      value={{
        ...layoutState,
        rtlLocale: defaultConfig.rtlLocale,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
