
export interface IBreadcrumbItemPropType {
    name: string;
    path?: string | undefined;
}

export class BreadcrumbItemProps implements IBreadcrumbItemPropType {
    name: string;
    path?: string | undefined;

    constructor(name: string, path: string | undefined = undefined) {
        this.name = name;
        this.path = path;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['name'] = this.name;
        data['path'] = this.path;
        return data;
    }
}

export class AppRouteData implements IAppRouteData {
    title?: string | undefined;
    auth?: string[] | undefined;
    breadcrumbs?: BreadcrumbItemProps[] | undefined;

    constructor(data?: IAppRouteData) {
        if (data) {
            for (const property in data) {
                if (Object.prototype.hasOwnProperty.call(data, property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.title = _data['title'];
            this.auth = _data['auth'];
            if (Array.isArray(_data['breadcrumbs'])) {
                this.breadcrumbs = [] as any;
                for (const item of _data['breadcrumbs'])
                    this.breadcrumbs!.push(new BreadcrumbItemProps(item['name'], item['path']));
            }
        }
    }

    static fromJS(data: any): AppRouteData {
        data = typeof data === 'object' ? data : {};
        const result = new AppRouteData();
        result.init(data);
        return result;
    }

    static create(title: string, auth: string[] | undefined, breadcrumbs: BreadcrumbItemProps[]) {
        const result =  new AppRouteData();
        result.title = title;
        result.auth = auth;
        result.breadcrumbs = breadcrumbs;
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['auth'] = this.auth;
        if (Array.isArray(this.breadcrumbs)) {
            data['breadcrumbs'] = [];
            for (const item of this.breadcrumbs)
                data['breadcrumbs'].push(item.toJSON());
        }
        return data;
    }
}

export interface IAppRouteData {
    title?: string | undefined;
    auth?: string[] | undefined;
    breadcrumbs?: IBreadcrumbItemPropType[] | undefined;
}

