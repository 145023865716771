import {ParticipantSessionReviewFile} from './ParticipantSessionReviewFile';

export class ParticipantSessionReview implements IParticipantSessionReview {
    dateCreated?: Date;
    dateUpdated?: Date;
    dateLastViewed?: Date | undefined;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    introduction?: string | undefined;
    additionalNotes?: string | undefined;
    reviewFiles?: ParticipantSessionReviewFile[] | undefined;

    constructor(data?: IParticipantSessionReview) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.dateLastViewed = _data['dateLastViewed'] ? new Date(_data['dateLastViewed'].toString()) : <any>undefined;
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.introduction = _data['introduction'];
            this.additionalNotes = _data['additionalNotes'];
            if (Array.isArray(_data['reviewFiles'])) {
                this.reviewFiles = [] as any;
                for (const item of _data['reviewFiles'])
                    this.reviewFiles!.push(ParticipantSessionReviewFile.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ParticipantSessionReview {
        data = typeof data === 'object' ? data : {};
        const result = new ParticipantSessionReview();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['dateLastViewed'] = this.dateLastViewed ? this.dateLastViewed.toISOString() : <any>undefined;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['introduction'] = this.introduction;
        data['additionalNotes'] = this.additionalNotes;
        if (Array.isArray(this.reviewFiles)) {
            data['reviewFiles'] = [];
            for (const item of this.reviewFiles)
                data['reviewFiles'].push(item.toJSON());
        }
        return data;
    }
}

export interface IParticipantSessionReview {
    dateCreated?: Date;
    dateUpdated?: Date;
    dateLastViewed?: Date | undefined;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    introduction?: string | undefined;
    additionalNotes?: string | undefined;
    reviewFiles?: ParticipantSessionReviewFile[] | undefined;
}