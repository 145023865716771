import {FileMetadataBase, IFileMetadataBase} from './FileMetadataBase';

export class PersonFileMetadata extends FileMetadataBase implements IPersonFileMetadata {
  personId?: string;
  filePath?: string | undefined;

  constructor(data?: IPersonFileMetadata) {
    super(data);
  }

  init(_data?: any) {
    super.init(_data);
    if (_data) {
      this.personId = _data['personId'];
      this.filePath = _data['filePath'];
    }
  }

  static fromJS(data: any): PersonFileMetadata {
    data = typeof data === 'object' ? data : {};
    const result = new PersonFileMetadata();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['personId'] = this.personId;
    data['filePath'] = this.filePath;
    super.toJSON(data);
    return data;
  }
}

export interface IPersonFileMetadata extends IFileMetadataBase {
  personId?: string;
  filePath?: string | undefined;
}