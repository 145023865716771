import {AppState} from '../store';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import { filesApiConfig} from '../../config';
import {FileMetadata, mapToFileMetadata} from '../../types/models/files/FileMetadata';
import {FILE_CACHE, fileCacheError, fileCacheMerge, fileCacheUpdate} from './Actions';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {IFilesCacheState} from './Reducer';
import {PayloadAction} from '@reduxjs/toolkit';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {AxiosResponse} from 'axios';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const fileCacheState = (state: AppState) => state.fileCache;

export function* LoadFileCacheItems(idList: string[]) {
  console.log('SAGA - fileCacheSaga - LoadFileCacheItems', idList);
  try {
    const res: AxiosResponse = yield authenticatedApi.request({
      baseURL: filesApiConfig.Url,
      method: 'PUT',
      url: '/files/list',
      data: idList
    });
    const files: FileMetadata[] = res.data.map((x: any) => mapToFileMetadata(x));
    yield put(fileCacheMerge(files));
  } catch (err) {
    yield put(fileCacheError(parseErrorInfo(err)));
  }
}

export function* AddFileCacheItems() {
  console.log('SAGA - fileCacheSaga - AddFileCacheItems');
  yield call(delay, 200);
  const currentFileCache: IFilesCacheState = yield select(fileCacheState);
  const currentPendingList = currentFileCache.filePendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    yield put(fileCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadFileCacheItems(){
  yield takeEvery(FILE_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadFileCacheItems(payload));
}

export function* watchAddFileCacheItems(){
  yield takeEvery(FILE_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddFileCacheItems());
}

export default function* fileCacheSaga() {
  yield all ([
    call(watchLoadFileCacheItems),
    call(watchAddFileCacheItems)
  ])
}