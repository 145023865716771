import {createAction} from '@reduxjs/toolkit';
import {ICommonLoadActionType} from '../Common.actions';
import {ICountry} from '../../types/models/Country';
import {ParticipantUserSettings} from '../../types/views/ParticipantUserSettings';

interface ITagsUpdateActionTypes {
    UPDATE_FILE: string;
    UPDATE_SYSTEM_FILE: string;
}

export interface SettingsActionTypes extends ICommonLoadActionType {
    LOCALITIES_LOAD: string;
    LOCALITIES_LOADED: string;
    LOCALITIES_ERROR: string;
}

export const TAGS: ITagsUpdateActionTypes = {
    UPDATE_FILE: 'tags/updateFileTags',
    UPDATE_SYSTEM_FILE: 'tags/updateFileSystemTags',
}

export const SETTINGS: SettingsActionTypes = {
    REFRESH: 'settings/refresh',
    LOAD: 'settings/load',
    LOADED: 'settings/loaded',
    ERROR: 'settings/error',
    LOCALITIES_LOAD: 'localities/load',
    LOCALITIES_LOADED: 'localities/loaded',
    LOCALITIES_ERROR: 'localities/error',
}

export const settingsLoad = createAction(SETTINGS.LOAD);
export const settingsLoaded = createAction<ParticipantUserSettings>(SETTINGS.LOADED);
export const settingsError = createAction<string>(SETTINGS.ERROR);
export const localitiesLoad = createAction(SETTINGS.LOCALITIES_LOAD);
export const localitiesLoaded = createAction<ICountry[]>(SETTINGS.LOCALITIES_LOADED);
export const localitiesError = createAction<string>(SETTINGS.LOCALITIES_ERROR);
export const tagsUpdateFile = createAction<string[]>(TAGS.UPDATE_FILE);
export const tagsUpdateFileSystem = createAction<string[]>(TAGS.UPDATE_SYSTEM_FILE);
