import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {responsiveFontSizes, StyledEngineProvider} from '@mui/material';
import defaultConfig from '../../utility/ContextProvider/defaultConfig';


interface IAppThemeProvider {
  children: React.ReactNode;
}
const AppThemeProvider: React.FC<IAppThemeProvider> = (props) => {
  const customTheme = createTheme(defaultConfig.theme);
  const responsiveTheme = responsiveFontSizes(customTheme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={responsiveTheme}>
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default React.memo(AppThemeProvider);
