export interface ParticipantPaths {
    ParticipantProfile: string,
    ParticipantEdit: string,
}

export const participantPaths: ParticipantPaths = {
    ParticipantProfile: '/profile/view',
    ParticipantEdit: '/profile/edit',
};

