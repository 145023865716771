import {createAction} from '@reduxjs/toolkit';
import {AuthUser} from '../../types/models/AuthUser';

export interface AuthActionTypes {
  LOGIN: string;
  CHECK: string;
  REFRESH: string;
  SUCCESS: string;
  ERROR: string;
  LOGOUT: string;
}

export const AUTH: AuthActionTypes = {
  LOGIN: 'auth/login',
  CHECK: 'auth/check',
  REFRESH: 'auth/refresh',
  SUCCESS: 'auth/success',
  ERROR: 'auth/error',
  LOGOUT: 'auth/logout'
}

// Used for initial login to get user and trigger session load
export const authLogin = createAction(AUTH.LOGIN);
// Used to refresh if needed (for page reloads etc)
export const authCheck = createAction(AUTH.CHECK);
// Used to reload the user from the API if a token is present
export const authRefresh = createAction(AUTH.REFRESH);
// Populate redux with any of the above
export const authSuccess = createAction<AuthUser>(AUTH.SUCCESS);
export const authError = createAction<string>(AUTH.ERROR);
// Clear AppState and get out
export const authLogOut = createAction(AUTH.LOGOUT);
