import {ICommonSaveActionType, ICommonSavePayload} from '../Common.actions';
import {IError} from '../../@template/helpers/errors';
import {createAction} from '@reduxjs/toolkit';
import {CurrentUserProfileEdit} from '../../types/requests/CurrentUserProfileEdit';
import {CurrentUserProfileView} from '../../types/views/CurrentUserProfileView';


export const PARTICIPANT_PROFILE: ICommonSaveActionType = {
    SAVE: 'participantProfile/save',
    SAVED: 'participantProfile/saveSuccess',
    SAVE_ERROR: 'participantProfile/saveError',
    LOAD: 'participantProfile/load',
    REFRESH: 'participantProfile/refresh',
    LOAD_ERROR: 'participantProfile/loadError',
    LOADED: 'participantProfile/loadSuccess',
}

export const participantProfileSave = createAction<ICommonSavePayload<CurrentUserProfileEdit>>(PARTICIPANT_PROFILE.SAVE);
export const participantProfileSaveSuccess = createAction<CurrentUserProfileView>(PARTICIPANT_PROFILE.SAVED);
export const participantProfileSaveError = createAction<IError>(PARTICIPANT_PROFILE.SAVE_ERROR);
export const participantProfileLoad = createAction(PARTICIPANT_PROFILE.LOAD);
export const participantProfileRefresh = createAction(PARTICIPANT_PROFILE.REFRESH);
export const participantProfileLoadSuccess = createAction<CurrentUserProfileView>(PARTICIPANT_PROFILE.LOADED);
export const participantProfileLoadError = createAction<string>(PARTICIPANT_PROFILE.LOAD_ERROR);
