
export const isEmptyOrWhitespace = (value: string | undefined | null) => {
  return (!value || value.length == 0 || value.match(/^ *$/) !== null);
};

export const toSoftBrokenString = (value: string | undefined | null, breakChars = 10) => {
  if (!value) {
    return '';
  } else {
    const hyphen = String.fromCharCode(0x00AD);
    const unbroken = new RegExp('([^\\s-]{' + breakChars + '})', 'g');
    return value.replace(unbroken, '$&'+hyphen);
  }
};

export const splitCase = (value: string | undefined) => {
  if (!value) {
    return '';
  }else {
    return value.replace(/([A-Z][a-z])/g, ' $1');
  }
};

export const truncate = (source: string | undefined, n: number, useWordBoundary = true) => {
  if (!source){ return undefined; }
  if (source.length <= n) { return source; }
  const subString = source.substr(0, n-1);
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString) + '...';
};

const allAscii = '.0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export const generateRandomKey = (
    passwordLength = 64,
    passwordChars = allAscii,
) =>
    [...window.crypto.getRandomValues(new Uint32Array(passwordLength))]
        .map(x => passwordChars[x % passwordChars.length])
        .join('');