import {AnyAction, combineReducers, Reducer} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import rootSaga from './saga';
import authReducer from './auth/Reducer';
import { createBrowserHistory } from 'history';
import filesCacheReducer from './fileCache/Reducer';
import settingsReducer from './settings/Reducer';
import sessionReducer from './session/Reducer';
import participantReducer from './participantProfile/Reducer';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

export type AppState = ReturnType<typeof rootReducer>;

const combinedReducers: Reducer = combineReducers({
  router: connectRouter(history),
  authLocal: authReducer,
  fileCache: filesCacheReducer,
  participantProfile: participantReducer,
  sessions: sessionReducer,
  settings: settingsReducer,
});

const rootReducer: Reducer = (state: AppState, action: AnyAction) => {
  if (action.type === 'root/reset') {
    state = {} as AppState;
  }
  return combinedReducers(state, action);
};


const enhancers = [];
const windowIfDefined =
  typeof window === 'undefined' ? null : (window as any);
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}
const middleware = [sagaMiddleware, routerMiddleware(history)];

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware
});
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export { history }
export default store;


