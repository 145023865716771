export class AgeRange implements IAgeRange {
    id?: string | undefined;
    title?: string | undefined;
    fromAge?: number | undefined;

    constructor(data?: IAgeRange) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.title = _data['title'];
            this.fromAge = _data['fromAge'];
        }
    }

    static fromJS(data: any): AgeRange {
        data = typeof data === 'object' ? data : {};
        const result = new AgeRange();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['title'] = this.title;
        data['fromAge'] = this.fromAge;
        return data;
    }
}

export interface IAgeRange {
    id?: string | undefined;
    title?: string | undefined;
    fromAge?: number | undefined;
}