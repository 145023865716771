import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {setAuthToken} from '../services/auth/authApi/authenticatedApi';
import {AuthUser} from '../../types/models/AuthUser';
import {authError, authLogOut, authRefresh} from '../../redux/auth/Actions';
import {useMsal} from '@azure/msal-react';
import {selectAuth, selectCurrentUser} from '../../redux/auth/Selectors';
import {AppDispatch, AppState} from '../../redux/store';
import {SilentRequest} from '@azure/msal-browser';
import {authConfig} from '../../config';
import {selectCountriesLoading, selectSettingsLoading, setSettingsInitialized} from '../../redux/settings/Selectors';
import {localitiesLoad, settingsLoad} from '../../redux/settings/Actions';
import useRecursiveTimeout from './AppPolling';


export const useAuthToken = (permissions: string[] | undefined): [boolean] => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {loggingIn, refreshing, currentUser, error} = useSelector(selectAuth);

  const { instance } = useMsal();
  const accountId = localStorage.getItem('AUTH_ACCT_ID');

  const isInitialised  = useSelector(setSettingsInitialized);
  const settingsLoading = useSelector(selectSettingsLoading);
  const countriesLoading = useSelector(selectCountriesLoading);

  const validateAuth = async () => {
    const expiryRaw = localStorage.getItem('AUTH_EXPIRY');
    console.log('expiry: ', expiryRaw, 'loggingIn: ', loggingIn, 'isInitialised: ', isInitialised, 'settingsLoading: ', settingsLoading, 'currentUser: ', currentUser);
    let isExpired = true;
    if (expiryRaw) {
      const expDate =  new Date(expiryRaw);
      isExpired = expDate < new Date();
      console.log('AUTH EXPIRY CHECK', isExpired, expDate);
    }
    if ((!currentUser || isExpired) && !loggingIn && !refreshing && !error && accountId) {
      // We can use accounts[0] because we limit the accounts by tenant.
      const currentAccount = instance.getAccountByHomeId(accountId)
      const loginRequest: SilentRequest = {
        scopes: [`api://${authConfig.clientId}/admin.user`, 'User.Read', 'email'],
        account: currentAccount || undefined
      };

      instance.acquireTokenSilent(loginRequest).then(tokenResponse => {
        setAuthToken(tokenResponse.accessToken, tokenResponse.expiresOn, accountId);
        if (!loggingIn && !currentUser) {
          console.log('AUTH - valid token but no user - refreshing user');
          dispatch(authRefresh());
        }
        if (!isInitialised && !settingsLoading) {
          console.log('AUTH - session data missing - refreshing user');
          dispatch(settingsLoad());
          dispatch(localitiesLoad());
        }
        return Promise.resolve();
      }).catch((err: any) => {
        console.log('AUTH ERROR, Logging out', err);
        dispatch(authError(err));
        dispatch(authLogOut());
        setLoading(false);
        return Promise.resolve();
      });
    } else {
      setLoading(false);
      return Promise.resolve();
    }
  };

  useRecursiveTimeout(() => validateAuth(), 600000);

  useEffect(() => {
    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        console.log('Check auth', loggingIn, refreshing, settingsLoading, currentUser);
        if (!loggingIn && !refreshing && !settingsLoading && currentUser)
          setLoading(false);
      }).catch(err => {
        dispatch(authLogOut());
        setLoading(false);
        console.log(err.toString());
      });
    };
    checkAuth();
  }, [instance, permissions, currentUser, dispatch, loggingIn, refreshing, error, accountId, settingsLoading]);

  return [loading];
};

export const useAuthUser = (): AuthUser | undefined => {
  const currentUser = useSelector(selectCurrentUser);
  if (currentUser) {
    return currentUser;
  }
  return undefined;
};

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
