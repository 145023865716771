import React from 'react';
import {Provider} from 'react-redux';
import ContextProvider from '@template/utility/ContextProvider';
import store from './redux/store';
import CssBaseline from '@mui/material/CssBaseline';
import {Configuration, PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {authConfig} from './config';
import CacheBuster, {ICacheBusterState} from './@template/core/CacheBuster';
import {LocalizationProvider} from '@mui/x-date-pickers';
import AppThemeProvider from './@template/utility/AppThemeProvider';
import AppStyleProvider from './@template/utility/AppStyleProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import routes from './modules';
import {RouterProvider} from 'react-router-dom';
import CacheReloadDialog from './@template/core/CacheReloadDialog';

export const msalConfig: Configuration = {
  auth: {
    clientId: `${authConfig.clientId}`,
    authority: `${authConfig.authority}`,
    redirectUri: `${authConfig.redirectUri}`,
    postLogoutRedirectUri: `${authConfig.postLogoutRedirectUri}`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => (
  <CacheBuster>
    {({ isLatestVersion, refreshCacheAndReload }: ICacheBusterState) => {
     return (<MsalProvider instance={msalInstance}>
        <ContextProvider>
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AppThemeProvider>
                <AppStyleProvider>
                  <CssBaseline />
                  <CacheReloadDialog isLatestVersion={isLatestVersion} refreshCacheAndReload={refreshCacheAndReload} />
                  <RouterProvider router={routes} />
                </AppStyleProvider>
              </AppThemeProvider>
            </LocalizationProvider>
          </Provider>
        </ContextProvider>
      </MsalProvider>);
    }}
  </CacheBuster>

);

export default App;
