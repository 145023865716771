import {Gender, PrimaryHand} from '../models/Enums';

export class CurrentUserProfileView implements ICurrentUserProfileView {
    dateCreated?: Date;
    firstName?: string | undefined;
    knownAsName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    gender?: Gender | undefined;
    customGender?: string | undefined;
    ageRange?: string | undefined;
    primaryHand?: PrimaryHand | undefined;

    constructor(data?: ICurrentUserProfileView) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.firstName = _data['firstName'];
            this.knownAsName = _data['knownAsName'];
            this.lastName = _data['lastName'];
            this.email = _data['email'];
            this.jobTitle = _data['jobTitle'];
            this.jobDepartment = _data['jobDepartment'];
            this.gender = _data['gender'];
            this.customGender = _data['customGender'];
            this.ageRange = _data['ageRange'];
            this.primaryHand = _data['primaryHand'];
        }
    }

    static fromJS(data: any): CurrentUserProfileView {
        data = typeof data === 'object' ? data : {};
        const result = new CurrentUserProfileView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['firstName'] = this.firstName;
        data['knownAsName'] = this.knownAsName;
        data['lastName'] = this.lastName;
        data['email'] = this.email;
        data['jobTitle'] = this.jobTitle;
        data['jobDepartment'] = this.jobDepartment;
        data['gender'] = this.gender;
        data['customGender'] = this.customGender;
        data['ageRange'] = this.ageRange;
        data['primaryHand'] = this.primaryHand;
        return data;
    }
}

export interface ICurrentUserProfileView {
    dateCreated?: Date;
    firstName?: string | undefined;
    knownAsName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    gender?: Gender | undefined;
    customGender?: string | undefined;
    ageRange?: string | undefined;
    primaryHand?: PrimaryHand | undefined;
}
