import {hasItems} from './arrays';
import {truncate} from './strings';


export const parseErrorInfo = (errorObj: any | undefined) => {
  if (!errorObj) return 'Error unspecified';
  if (typeof errorObj === 'object' && !Array.isArray(errorObj)) {
    console.log('errorObj', errorObj)
    const errorParts: string[] = [];
    if (Object.prototype.hasOwnProperty.call(errorObj, 'statusCode')) errorParts.push(`[Error: ${errorObj.statusCode}]`);
    if (Object.prototype.hasOwnProperty.call(errorObj, 'status')) errorParts.push(`[Error: ${errorObj.status}]`);
    if (Object.prototype.hasOwnProperty.call(errorObj, 'statusText')) errorParts.push(errorObj.statusText);
    if (Object.prototype.hasOwnProperty.call(errorObj, 'data')) errorParts.push(truncate(errorObj.data.toString(), 50)??'');
    if (Object.prototype.hasOwnProperty.call(errorObj, 'error')) errorParts.push(errorObj.error);
    if (Object.prototype.hasOwnProperty.call(errorObj, 'message') && hasItems(errorObj.message)) {
      if (Object.prototype.hasOwnProperty.call(errorObj.message[0],'messages') && hasItems(errorObj.message[0].message)) {
        if (Object.prototype.hasOwnProperty.call(errorObj.message[0].messages[0],'error')) errorParts.push(errorObj.message[0].messages[0].message);
      }
    }
    if (hasItems(errorParts)) return errorParts.join(', ');
  }
  console.log(errorObj);
  return 'Error occurred (unrecognised format)';
}

export interface IError {
  statusCode: number | undefined;
  message: string | undefined;
}

export const parseErrorToErrorClass = (errorObj: any | undefined) => {
  if (!errorObj) return {statusCode: undefined, message: 'Error unspecified'};
  if (typeof errorObj === 'object' && !Array.isArray(errorObj)) {
    console.log('errorObj', errorObj)
    const res: IError = {statusCode: undefined, message: undefined};
    if (!res.statusCode && Object.prototype.hasOwnProperty.call(errorObj, 'status')) {
      res.statusCode = errorObj.status;
    }
    if (!res.statusCode && Object.prototype.hasOwnProperty.call(errorObj, 'statusCode')) {
      res.statusCode = errorObj.statusCode;
    }
    if (!res.message && Object.prototype.hasOwnProperty.call(errorObj, 'data')){
      const data = errorObj.data;
      if(Object.prototype.hasOwnProperty.call(data, 'title')){
        res.message = data.title;
      }
    }
    if (!res.message && Object.prototype.hasOwnProperty.call(errorObj, 'error')){
      res.message = errorObj.error;
    }
    if (!res.message && Object.prototype.hasOwnProperty.call(errorObj, 'message')){
      if(typeof errorObj.message === 'string'){
        res.message = errorObj.message;
      }else if(typeof errorObj.message === 'object'){
        // If this ever comes up we will want to expand this
        res.message = errorObj.message.toString();
      }
    }
    if (!res.message && Object.prototype.hasOwnProperty.call(errorObj, 'statusText')){
      res.message = errorObj.statusText;
    }
    return res;
  }
  if (typeof errorObj === 'string'){
    return {statusCode: undefined, message: errorObj};
  }
  return {statusCode: undefined, message: 'Error occurred (unrecognised format)'};
}