import sessionRouteConfig from './sessions';
import participantRouteConfig from './participantProfile';
import {AppRouteData, BreadcrumbItemProps} from '../types/AppRoutesPropType';
import ExternalLayout from '../@template/core/AppLayout/ExternalLayout';
import React from 'react';
import authRouteConfig from './auth';
import ErrorPage from './errorPages/Error500';
import errorPagesConfig from './errorPages';
import AppLayout from '../@template/core/AppLayout';
import {createBrowserRouter} from 'react-router-dom';

const internalRouteConfigs = [
    ...authRouteConfig,
    ...sessionRouteConfig,
    ...participantRouteConfig
];

const defaultSectionBreadcrumbs: BreadcrumbItemProps[] = [];

const defaultRouteConfig = [
    {
        path: '/signin', element: <ExternalLayout />,
        handle: AppRouteData.create('Welcome', undefined, defaultSectionBreadcrumbs),
        errorElement: <ErrorPage />,
        children: [
            ...authRouteConfig
        ]
    } ,
    { path: '/error',
        element: <ExternalLayout />,
        handle: AppRouteData.create('Error', undefined, defaultSectionBreadcrumbs),
        errorElement: <ErrorPage />,
        children: [
            ...errorPagesConfig
        ]
    } ,
    { path: '/',
        element: <AppLayout />,
        handle: AppRouteData.create('Internal', undefined, defaultSectionBreadcrumbs),
        errorElement: <ErrorPage />,
        children: [
            ...internalRouteConfigs
        ]
    } ,
];

const defaultRoutes = createBrowserRouter([...defaultRouteConfig]);


export default defaultRoutes;
