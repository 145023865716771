import {AuthUser} from '../../types/models/AuthUser';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {AUTH} from './Actions';
import {Action} from 'redux';


export interface IAuthState {
  currentUser: AuthUser | undefined;
  loggingIn: boolean;
  refreshing: boolean;
  loggingOut: boolean;
  error: string | undefined;
}

const initialState: IAuthState = {
  currentUser: undefined,
  loggingIn: false,
  refreshing: false,
  loggingOut: false,
  error: undefined,
}

const authReducer = createReducer(initialState, {
    [AUTH.SUCCESS]: (state, action: PayloadAction<AuthUser>) => {
      state.currentUser = action.payload;
      state.loggingIn = false;
      state.refreshing = false;
      state.error = undefined;
    },
    [AUTH.REFRESH]: (state) => {
      state.refreshing = true;
      state.error = undefined;
    },
    [AUTH.ERROR]: (state, action: PayloadAction<string>) => {
      state.loggingIn = false;
      state.refreshing = false;
      state.currentUser = undefined;
      state.error = action.payload;
    },
    [AUTH.CHECK]: (state, action: Action) => {
      state.refreshing = false;
      state.loggingOut = false;
    },
    [AUTH.LOGIN]: (state, action: Action) => {
      state.loggingIn = true;
      state.refreshing = false;
      state.loggingOut = false;
      state.error = undefined;
    }
});

export default authReducer;