import React, {useEffect, useRef, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MobileStepper, Theme} from '@mui/material';
import DialogCloseButton from '../DialogCloseButton';
import ReactMarkdown from 'react-markdown';
import {LoadingButton} from '@mui/lab';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import {hasItems} from '../../helpers/arrays';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'inherit',
    backgroundColor: 'white'
  }
}));

interface IChangeLog {
  major_version: number;
  minor_version: number;
  title: string;
  description: string;
  image_url: string;
}

interface ICacheReloadDialogProps {
  isLatestVersion: boolean;
  refreshCacheAndReload(): void;
}

interface IPrevLatestVersion {
  current: boolean | undefined;
}

const CacheReloadDialog: React.FC<ICacheReloadDialogProps> = ({isLatestVersion, refreshCacheAndReload}) => {
  const classes = useStyles();
  const [changeLog, setChangeLog] = useState<IChangeLog[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const maxSteps = changeLog.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const prevLatestVersionRef = useRef(isLatestVersion);
  useEffect(() => {
    prevLatestVersionRef.current = isLatestVersion;
  });
  const prevIsLatestVersion = prevLatestVersionRef.current;

  useEffect(() => {
    if (prevIsLatestVersion && !isLatestVersion) {
      fetch('/changelog.json')
        .then((response) => response.json())
        .then((changeLog) => {
          setChangeLog(changeLog);
        });
    }
  }, [isLatestVersion]);


  return <>
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={!isLatestVersion}
    >
      <DialogTitle>
        A New Update has Arrived
        <DialogCloseButton onClick={refreshCacheAndReload}/>
      </DialogTitle>

      <DialogContent dividers={true}>
        {hasItems(changeLog) && <div key={changeLog[activeStep].title}>
          <div className='nested'>
            <h3>{changeLog[activeStep].title}</h3>
            <ReactMarkdown>{changeLog[activeStep].description}</ReactMarkdown>
            {changeLog[activeStep].image_url !== '' && <img src={changeLog[activeStep].image_url}  alt="This release features"/>}
            <h5>Version {changeLog[activeStep].major_version}.{changeLog[activeStep].minor_version}</h5>
          </div>
        </div>}
        <MobileStepper
          className={classes.root}
          variant="text"
          steps={maxSteps}
          activeStep={activeStep}
          nextButton={
            <Button size="small" color="primary" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              <KeyboardArrowRight /> More versions
            </Button>
          }
          backButton={
            <Button color="secondary" size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft /> Back
            </Button>
          }
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          onClick={refreshCacheAndReload}
        >Load Changes</LoadingButton>
      </DialogActions>
    </Dialog>
  </>;
}

export default CacheReloadDialog;
