import React, {useContext} from 'react';
import AppContext from '../../utility/AppContext';
import Box from '@mui/material/Box';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {Theme} from '@mui/material';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {siteConfig} from '../../../config';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    '& .footerContainer': {
      padding: '5px 20px',
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32,
      },
      [theme.breakpoints.up('xl')]: {
        padding: '10px 32px',
      },
    },
  },
  btnRoot: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const AppFooter: React.FC = () => {
  const {footer, footerType, navStyle} = useContext<AppContextPropsType>(
    AppContext,
  );

  const classes = useStyles();

  const versionName = process.env.REACT_APP_VERSION ?? '0';

  return (
    <>
      {footer &&
      footerType === 'fluid' &&
      navStyle !== 'h-default' &&
      navStyle !== 'hor-light-nav' &&
      navStyle !== 'hor-dark-layout' ? (
        <Box className={clsx(classes.footer, 'footer')}>
          <Box
            className='footerContainer'
            alignItems='center'
            flexDirection='row'
            display='flex'>
            <Box>Copyright Operator Pty Ltd 2023 (v{versionName})</Box>
            {siteConfig.debugFooter && <>
              <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'block', sm: 'none' } }}>(size: xs)</Box>
              <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'none', sm: 'block', md: 'none' } }}>(size: sm)</Box>
              <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'none', md: 'block', lg: 'none' } }}>(size: md)</Box>
              <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'none', lg: 'block', xl: 'none' } }}>(size: lg)</Box>
              <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'none', xl: 'block' } }}>(size: xl)</Box>
            </>}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default AppFooter;
