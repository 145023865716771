import React, {ReactNode} from 'react';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {jssPreset, StylesProvider} from '@mui/styles';
import initialiseYup from '../../validation';
// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, rtl()]});


interface IOperatorStyleProvider {
  children: ReactNode;
}


const OperatorStyleProvider: React.FC<IOperatorStyleProvider> = props => {
  initialiseYup();
  return <StylesProvider jss={jss}>{props?.children}</StylesProvider>;
};
export default OperatorStyleProvider;
