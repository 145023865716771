import {FileMetadataBase, IFileMetadataBase} from './FileMetadataBase';

export class UserFileMetadata extends FileMetadataBase implements IUserFileMetadata {
  userPersonId?: string;
  filePath?: string | undefined;

  constructor(data?: IUserFileMetadata) {
    super(data);
  }

  init(_data?: any) {
    super.init(_data);
    if (_data) {
      this.userPersonId = _data['userPersonId'];
      this.filePath = _data['filePath'];
    }
  }

  static fromJS(data: any): UserFileMetadata {
    data = typeof data === 'object' ? data : {};
    const result = new UserFileMetadata();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userPersonId'] = this.userPersonId;
    data['filePath'] = this.filePath;
    super.toJSON(data);
    return data;
  }
}

export interface IUserFileMetadata extends IFileMetadataBase {
  userPersonId?: string;
  filePath?: string | undefined;
}