import {CurrentUserProfileView} from '../../types/views/CurrentUserProfileView';
import {IError} from '../../@template/helpers/errors';
import {PARTICIPANT_PROFILE} from './Actions';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';

export interface IParticipantProfileState {
    participant: CurrentUserProfileView | undefined;
    participantLoading: boolean | false;
    participantLoadError: string | undefined;
    participantSaving: boolean | false;
    participantSaveError: IError | undefined;
}

const initialState: IParticipantProfileState = {
    participant: undefined,
    participantLoading: false,
    participantLoadError: undefined,
    participantSaving: false,
    participantSaveError: undefined
};

const participantReducer = createReducer(initialState, {
    [PARTICIPANT_PROFILE.LOAD]: (state) => {
        state.participant = undefined;
        state.participantLoading = true;
        state.participantLoadError = undefined;
        state.participantSaveError = undefined;
    },
    [PARTICIPANT_PROFILE.LOADED]: (state, action: PayloadAction<CurrentUserProfileView>) => {
        state.participant = action.payload;
        state.participantLoading = false;
        state.participantLoadError = undefined;
        state.participantSaveError = undefined;
    },
    [PARTICIPANT_PROFILE.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
        state.participant = undefined;
        state.participantLoading = false;
        state.participantLoadError = action.payload;
    },
    [PARTICIPANT_PROFILE.SAVE]: (state) => {
        state.participantSaving = true;
        state.participantSaveError = undefined;
    },
    [PARTICIPANT_PROFILE.SAVED]: (state, action: PayloadAction<CurrentUserProfileView>) => {
        state.participant = action.payload;
        state.participantSaving = false;
        state.participantSaveError = undefined;
    },
    [PARTICIPANT_PROFILE.SAVE_ERROR]: (state, action: PayloadAction<IError>) => {
        state.participantSaving = false;
        state.participantSaveError = action.payload;
    },

});

export default participantReducer;