
export class Country implements ICountry {
    countryId?: number;
    name?: string | undefined;
    countryCode?: string | undefined;
    currencyCode?: string | undefined;
    cultureInfo?: string | undefined;
    states?: State[] | undefined;

    constructor(data?: ICountry) {
        if (data) {
            for (const property in data) {
                if (Object.prototype.hasOwnProperty.call(data, property))
                    (this as any)[property] = (data  as any)[property];
            }
        }
    }
}

export interface ICountry {
    countryId?: number;
    name?: string | undefined;
    countryCode?: string | undefined;
    currencyCode?: string | undefined;
    cultureInfo?: string | undefined;
    states?: State[] | undefined;
}

export class State implements IState {
    stateId?: number;
    title?: string | undefined;
    shortTitle?: string | undefined;

    constructor(data?: IState) {
        if (data) {
            for (const property in data) {
                if (Object.prototype.hasOwnProperty.call(data, property))
                    (this as any)[property] = (data  as any)[property];
            }
        }
    }
}

export interface IState {
    stateId?: number;
    title?: string | undefined;
    shortTitle?: string | undefined;
}