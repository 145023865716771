import {ParticipantSessionReview} from '../models/ParticipantSessionReview';

export class ParticipantSessionView implements IParticipantSessionView {
    participantSessionId?: string;
    sessionId?: string;
    accountCompanyName?: string | undefined;
    dateCreated?: Date;
    dateUpdated?: Date;
    dateLastViewed?: Date | undefined;
    instructorFirstname?: string | undefined;
    instructorLastname?: string | undefined;
    instructorFullname?: string | undefined;
    title?: string | undefined;
    dateSessionStart?: Date | undefined;
    dateSessionEnd?: Date | undefined;
    participantSessionReview?: ParticipantSessionReview | undefined;

    constructor(data?: IParticipantSessionView) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.participantSessionId = _data['participantSessionId'];
            this.sessionId = _data['sessionId'];
            this.accountCompanyName = _data['accountCompanyName'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.dateLastViewed = _data['dateLastViewed'] ? new Date(_data['dateLastViewed'].toString()) : <any>undefined;
            this.instructorFirstname = _data['instructorFirstname'];
            this.instructorLastname = _data['instructorLastname'];
            this.instructorFullname = _data['instructorFullname'];
            this.title = _data['title'];
            this.dateSessionStart = _data['dateSessionStart'] ? new Date(_data['dateSessionStart'].toString()) : <any>undefined;
            this.dateSessionEnd = _data['dateSessionEnd'] ? new Date(_data['dateSessionEnd'].toString()) : <any>undefined;
            this.participantSessionReview = _data['participantSessionReview'] ? ParticipantSessionReview.fromJS(_data['participantSessionReview']) : <any>undefined;
        }
    }

    static fromJS(data: any): ParticipantSessionView {
        data = typeof data === 'object' ? data : {};
        const result = new ParticipantSessionView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['participantSessionId'] = this.participantSessionId;
        data['sessionId'] = this.sessionId;
        data['accountCompanyName'] = this.accountCompanyName;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['dateLastViewed'] = this.dateLastViewed ? this.dateLastViewed.toISOString() : <any>undefined;
        data['instructorFirstname'] = this.instructorFirstname;
        data['instructorLastname'] = this.instructorLastname;
        data['instructorFullname'] = this.instructorFullname;
        data['title'] = this.title;
        data['dateSessionStart'] = this.dateSessionStart ? this.dateSessionStart.toISOString() : <any>undefined;
        data['dateSessionEnd'] = this.dateSessionEnd ? this.dateSessionEnd.toISOString() : <any>undefined;
        data['participantSessionReview'] = this.participantSessionReview ? this.participantSessionReview.toJSON() : <any>undefined;
        return data;
    }
}

export interface IParticipantSessionView {
    participantSessionId?: string;
    sessionId?: string;
    accountCompanyName?: string | undefined;
    dateCreated?: Date;
    dateUpdated?: Date;
    dateLastViewed?: Date | undefined;
    instructorFirstname?: string | undefined;
    instructorLastname?: string | undefined;
    instructorFullname?: string | undefined;
    title?: string | undefined;
    dateSessionStart?: Date | undefined;
    dateSessionEnd?: Date | undefined;
    participantSessionReview?: ParticipantSessionReview | undefined;
}
