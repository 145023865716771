import {all} from '@redux-saga/core/effects';
import authSaga from './auth/Sagas';
import sessionSaga from './session/Sagas';
import participantSaga from './participantProfile/Sagas';
import settingsSaga from './settings/Sagas';
import fileCacheSaga from './fileCache/Sagas';


export default function* rootSaga() {
  yield all([
    authSaga(),
    sessionSaga(),
    participantSaga(),
    settingsSaga(),
    fileCacheSaga()
  ]);
}
