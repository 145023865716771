import {
  FooterType,
  HeaderType,
  LayoutType,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius,
} from '../../../shared/constants/AppEnums';


export const cardRadius = ThemeStyleRadius.STANDARD;

const defaultConfig: any = {
  theme: {
    typography: {
      fontSize: 12,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: '1.8rem',
        fontWeight: 500,
        fontFamily: '"Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif'
      },
      h2: {
        fontSize: '1.6rem',
        fontWeight: 500,
        fontFamily: '"Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif'
      },
      h3: {
        fontSize: '1.4rem',
        fontWeight: 500,
        fontFamily: '"Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif'
      },
      h4: {
        fontSize: '1.2rem',
        fontWeight: 500,
        fontFamily: '"Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif'
      },
      h5: {
        fontSize: '1.1rem',
        fontWeight: 500,
        fontFamily: '"Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif'
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600,
        fontFamily: '"Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif'
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          contained: {
            borderRadius: cardRadius,
            boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
          },
          outlined: {
            borderRadius: cardRadius,
            boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
          },
          text: {
            '&:hover': {
              backgroundColor: '#F4F7FE',
            }
          }
        }
      },
      MuiButtonOutlined: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
          },
        }
      },
      MuiFilledInput: {
        defaultProps: {
          margin: 'dense',
        }
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: '#A8A8A8',
            borderColor: '#A8A8A8'
          },
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
        }
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        }
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
        styleOverrides: {
          root: {
            fontSize: 14,
            background: '#fcfdff',
          },
        }
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense',
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
        styleOverrides: {
          root: {
            background: '#fcfdff',
          },
          notchedOutline: {
            borderColor: '#a9afba',
          },
        }
      },
      MuiFab: {
        defaultProps: {
          size: 'small',
        }
      },
      MuiMenu: {
        defaultProps: {
          elevation: 2,
        },
        styleOverrides: {
          root: {
            boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.05)',
          },
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              marginRight: '4px'
            },
          },
        }
      },
      MuiPopover: {
        defaultProps: {
          elevation: 2,
          p: 2
        }
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        }
      },
      MuiTextField: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        }
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense',
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
          },
        }
      },
      MuiCardLg: {
        styleOverrides: {
          root: {
            borderRadius:
              cardRadius === ThemeStyleRadius.STANDARD
                ? ThemeStyleRadius.STANDARD
                : ThemeStyleRadius.MODERN + 20,
          },
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.05)',
          },
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            border: '1px solid #EFEFEF',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.05)',
            '& .MuiCardContent-root:last-child': {
              paddingBottom: 16,
            },
          },
        }
      },
      MuiChip: {
        defaultProps: {
          size: 'small'
        },
        styleOverrides: {
          root: {
            marginRight: 4,
            marginBottom: 2,
            padding: 1,
            borderRadius: '12px',
            height: 'auto',
          },
          label: {
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            lineHeight: '1rem'
          }
        }
      },
    },
    spacing: 3,
    direction: 'ltr', // ltr, rtl
    palette: {
      type: ThemeMode.LIGHT,
      background: {
        paper: '#FFFFFF',
        default: '#F6F8FE',
      },
      primary: {
        main: '#5166af',
        contrastText: '#fff',
      },
      secondary: {
        main: '#598eca',
      },
      sidebar: {
        bgColor: '#313541',
        textColor: '#808183',
      },
      grey: {
        50: '#fefefe',
        100: '#f7fafc',
        200: '#edf2f7',
        300: '#E0E0E0',
        400: '#c5c6cb',
        500: '#A8A8A8',
        600: '#666666',
        700: '#4a5568',
        800: '#201e21',
        900: '#1a202c',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
      text: {
        primary: '#676f78',
        secondary: '#74788d',
        disabled: '#909098',
        hint: '#aeafb8',
        white: '#fff',
      },
    },
    status: {
      danger: 'orange',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  themeStyle: ThemeStyle.STANDARD,
  themeMode: ThemeMode.SEMI_DARK,
  navStyle: NavStyle.MINI_SIDEBAR_TOGGLE,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FLUID,
  headerType: HeaderType.DARK,
  rtAnim: RouteTransition.NONE,
  footer: true,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  rtlLocale: ['ar'],
};
export default defaultConfig;
