export enum PhoneType {
  Mobile = 'Mobile',
  Work = 'Work',
  Home = 'Home',
  Other = 'Other',
}
export enum MetadataType {
  TempFile = 'TempFile',
  SystemFile = 'SystemFile',
  MessageFile = 'MessageFile',
  PersonFile = 'PersonFile',
  CompanyFile = 'CompanyFile',
  LibraryFile = 'LibraryFile',
  UserFile = 'UserFile',
}

export enum Gender {
  PreferToSelfDescribe = 'PreferToSelfDescribe',
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'NonBinary',
}

export enum PrimaryHand {
  Left = 'Left',
  Right = 'Right',
}
