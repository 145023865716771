import React from 'react';
import {NavLink as BaseNavLink} from 'react-router-dom';
import clsx from 'clsx';

const AppNavLink = React.forwardRef(
  ({ activeClassName, className, ...validProps } : any, ref: React.Ref<HTMLAnchorElement>) => (
    <BaseNavLink
      {...validProps}
      ref={ref}
      className={({ isActive }: any) => isActive ? clsx(activeClassName, className): className}
    />
  ),
);

AppNavLink.displayName = 'AppNavLink';

export default AppNavLink;
