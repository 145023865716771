import React, {useContext} from 'react';
import AppHeader from './AppHeader';
import {ContentView} from '../../../index';
import Box from '@mui/material/Box';
import useStyles from './index.style';
import AppContext from '../../../utility/AppContext';
import clsx from 'clsx';
import AppContextPropsType from '../../../../types/AppContextPropsType';

const MiniSidebarToggle: React.FC = () => {
  const {footer, themeStyle, layoutType, footerType} = useContext<
    AppContextPropsType
  >(AppContext);
  const classes = useStyles({footer, themeStyle});

  return (
    <Box
      className={clsx(
        classes.appMain,
        classes.boxedLayout
      )}>
      <Box className={clsx(classes.mainContent, 'main-content')}>
        <Box className={classes.mainContainer}>
          <AppHeader />
          <ContentView />
        </Box>
      </Box>
    </Box>
  );
};

export default MiniSidebarToggle;
