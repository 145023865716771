import {AxiosResponse} from 'axios';
import {externalApiConfig} from '../../config';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {ICommonSavePayload} from '../Common.actions';
import {CurrentUserProfileEdit} from '../../types/requests/CurrentUserProfileEdit';
import {CurrentUserProfileView} from '../../types/views/CurrentUserProfileView';
import {
    PARTICIPANT_PROFILE, participantProfileLoadError, participantProfileLoadSuccess,
    participantProfileSaveError,
    participantProfileSaveSuccess
} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {parseErrorInfo, parseErrorToErrorClass} from '../../@template/helpers/errors';

export function* SaveParticipant({ id, submission }: ICommonSavePayload<CurrentUserProfileEdit>) {
    console.log('SAGA - participantSaga - SaveParticipant');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: externalApiConfig.Url,
            method: id ? 'PUT' : 'POST',
            url: id ? `${externalApiConfig.Paths.participant}` : externalApiConfig.Paths.participant,
            data: submission
        });
        const participant: CurrentUserProfileView = CurrentUserProfileView.fromJS(res.data);
        yield put(participantProfileSaveSuccess(participant));
    } catch (err) {
        yield put(participantProfileSaveError(parseErrorToErrorClass(err)));
    }
}


export function* LoadParticipant() {
    console.log('SAGA - participantSaga - LoadParticipant');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: externalApiConfig.Url,
            method: 'GET',
            url: `${externalApiConfig.Paths.participant}`,
        });
        const participant: CurrentUserProfileView = CurrentUserProfileView.fromJS(res.data);
        yield put(participantProfileLoadSuccess(participant));
    } catch (err) {
        yield put(participantProfileLoadError(parseErrorInfo(err)));
    }
}

export function* watchSaveParticipant(){
    yield takeEvery(PARTICIPANT_PROFILE.SAVE, ({ payload } : PayloadAction<ICommonSavePayload<CurrentUserProfileEdit>>) => SaveParticipant(payload));
}

export function* watchLoadParticipant(){
    yield takeEvery(PARTICIPANT_PROFILE.LOAD, () => LoadParticipant());
}

export function* watchRefreshParticipant(){
    yield takeEvery(PARTICIPANT_PROFILE.REFRESH, () => LoadParticipant());
}


export default function* participantSaga() {
    yield all ([
        call(watchSaveParticipant),
        call(watchRefreshParticipant),
        call(watchLoadParticipant),
    ])
}