import {FileMetadataBase, IFileMetadataBase} from './FileMetadataBase';

export class SystemFileMetadata extends FileMetadataBase implements ISystemFileMetadata {
  systemType?: string | undefined;
  filePath?: string | undefined;

  constructor(data?: ISystemFileMetadata) {
    super(data);
  }

  init(_data?: any) {
    super.init(_data);
    if (_data) {
      this.systemType = _data['systemType'];
      this.filePath = _data['filePath'];
    }
  }

  static fromJS(data: any): SystemFileMetadata {
    data = typeof data === 'object' ? data : {};
    const result = new SystemFileMetadata();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['systemType'] = this.systemType;
    data['filePath'] = this.filePath;
    super.toJSON(data);
    return data;
  }
}

export interface ISystemFileMetadata extends IFileMetadataBase {
  systemType?: string | undefined;
  filePath?: string | undefined;
}