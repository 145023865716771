export class SessionSearchRequest implements ISessionSearchRequest {
    dateAddedFrom?: Date | undefined;
    dateAddedTo?: Date | undefined;

    constructor(data?: ISessionSearchRequest) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.dateAddedFrom = _data['dateAddedFrom'] ? new Date(_data['dateAddedFrom'].toString()) : <any>undefined;
            this.dateAddedTo = _data['dateAddedTo'] ? new Date(_data['dateAddedTo'].toString()) : <any>undefined;
        }
    }

    static fromJS(data: any): SessionSearchRequest {
        data = typeof data === 'object' ? data : {};
        const result = new SessionSearchRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['dateAddedFrom'] = this.dateAddedFrom ? this.dateAddedFrom.toISOString() : <any>undefined;
        data['dateAddedTo'] = this.dateAddedTo ? this.dateAddedTo.toISOString() : <any>undefined;
        return data;
    }
}

export interface ISessionSearchRequest {
    dateAddedFrom?: Date | undefined;
    dateAddedTo?: Date | undefined;
}