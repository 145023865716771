interface IRouteTabParameter {
  index: number;
  title: string;
  query: string;
}

export class RouteTabParameter implements IRouteTabParameter {
  index: number;
  title: string;
  query: string;

  constructor(index: number, title: string, query: string) {
    this.index = index;
    this.title = title;
    this.query = query;
  }
}