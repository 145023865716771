import 'react-app-polyfill/ie11';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

import './shared/styles/index.css';
import App from './App';
import '@template/services';
import {createRoot} from 'react-dom/client';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
