import {makeStyles} from '@mui/styles';
import React from 'react';
import useStyles from '../../../shared/jss/common/common.style';
import Box from '@mui/material/Box';
import {AppSuspense} from '../../index';
import Scrollbar from '../Scrollbar';
import AppErrorBoundary from '../AppErrorBoundary';
import {Outlet} from 'react-router-dom';
import {Theme} from '@mui/material';


const useStyle = makeStyles((theme: Theme) => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: '#f3f4f6',
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .main-content-view': {
      padding: 10,
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  contentScroll: {
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('lg')]: {
    },
  }
}));
const OperatorExternalLayout: React.FC = () => {
  useStyles();

  const classes = useStyle();
  return (
      <Box className={classes.appAuth}>
        <Scrollbar className={classes.contentScroll}>
          <Box
            display='flex'
            flex={1}
            ml={4}
            mr={4}
            flexDirection='column'
            className='main-content-view'>
            <AppSuspense>
              <AppErrorBoundary>
                <Outlet />
              </AppErrorBoundary>
            </AppSuspense>
          </Box>
        </Scrollbar>
      </Box>
  );
};

export default React.memo(OperatorExternalLayout);