export interface IAuthUser {
    firstName?: string | undefined;
    lastName?: string | undefined;
    readonly displayName?: string | undefined;
    email?: string | undefined;
    personId?: string;
    authUserId?: string | undefined;
    photoUrl?: string | undefined;
    permissions?: string[] | undefined;
    owners?: string[] | undefined;
}

export class AuthUser implements IAuthUser {
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    personId?: string;
    authUserId?: string | undefined;
    photoUrl?: string | undefined;
    permissions?: string[] | undefined;
    owners?: string[] | undefined;

    get displayName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    constructor(data?: IAuthUser) {
        if (data) {
            for (const property in data) {
                if (Object.prototype.hasOwnProperty.call(data, property))
                    (this as any)[property] = (data  as any)[property];
            }
        }
    }
}