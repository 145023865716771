import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import React from 'react';
import {participantPaths} from './paths';


const participantProfileBreadcrumbs = [new BreadcrumbItemProps('My details', (participantPaths.ParticipantProfile))];

const DetailPage = React.lazy(() => import('./ParticipantView'));
const EditPage = React.lazy(() => import('./ParticipantEdit'));

const participantRouteConfig = [
    { path: participantPaths.ParticipantProfile, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, participantProfileBreadcrumbs) } ,
    { path: participantPaths.ParticipantEdit, element: <EditPage />, handle: AppRouteData.create('Edit', undefined, participantProfileBreadcrumbs) } ,
];

export default participantRouteConfig;