import {MetadataType} from '../Enums';

export interface IFileUploadMetaModel extends IFileMetadataBase {
  uppyId?: string | undefined;
}

export abstract class FileMetadataBase implements IFileMetadataBase {
  fileId?: string;
  partitionKey?: string | undefined;
  metadataType?: MetadataType;
  fileExtensionType?: string | undefined;
  mimeType?: string | undefined;
  fileSize?: number;
  contentType?: string | undefined;
  abstract?: string | undefined;
  dateCreated?: Date;
  dateUpdated?: Date;
  createdByPersonId?: string | undefined;
  isTemporary?: boolean;
  isPrivate?: boolean;
  indexPending?: boolean;
  dateLastIndexed?: Date | undefined;
  updatedByUserId?: string | undefined;
  isDeleted?: boolean;
  deletedByUserId?: string | undefined;
  dateDeleted?: Date | undefined;
  cleanedUp?: boolean;
  storeFilename?: string | undefined;
  originalFilename?: string | undefined;
  tags?: string[] | undefined;

  constructor(data?: IFileMetadataBase) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.fileId = _data['fileId'];
      this.partitionKey = _data['partitionKey'];
      this.metadataType = _data['metadataType'];
      this.fileExtensionType = _data['fileExtensionType'];
      this.mimeType = _data['mimeType'];
      this.fileSize = _data['fileSize'];
      this.contentType = _data['contentType'];
      this.abstract = _data['abstract'];
      this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
      this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
      this.createdByPersonId = _data['createdByPersonId'];
      this.isTemporary = _data['isTemporary'];
      this.isPrivate = _data['isPrivate'];
      this.indexPending = _data['indexPending'];
      this.dateLastIndexed = _data['dateLastIndexed'] ? new Date(_data['dateLastIndexed'].toString()) : <any>undefined;
      this.updatedByUserId = _data['updatedByUserId'];
      this.isDeleted = _data['isDeleted'];
      this.deletedByUserId = _data['deletedByUserId'];
      this.dateDeleted = _data['dateDeleted'] ? new Date(_data['dateDeleted'].toString()) : <any>undefined;
      this.cleanedUp = _data['cleanedUp'];
      this.storeFilename = _data['storeFilename'];
      this.originalFilename = _data['originalFilename'];
      if (Array.isArray(_data['tags'])) {
        this.tags = [] as any;
        for (const item of _data['tags'])
          this.tags!.push(item);
      }
    }
  }

  static fromJS(data: any): FileMetadataBase {
    data = typeof data === 'object' ? data : {};
    throw new Error('The abstract class \'FileMetadataBase\' cannot be instantiated.');
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fileId'] = this.fileId;
    data['partitionKey'] = this.partitionKey;
    data['metadataType'] = this.metadataType;
    data['fileExtensionType'] = this.fileExtensionType;
    data['mimeType'] = this.mimeType;
    data['fileSize'] = this.fileSize;
    data['contentType'] = this.contentType;
    data['abstract'] = this.abstract;
    data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data['createdByPersonId'] = this.createdByPersonId;
    data['isTemporary'] = this.isTemporary;
    data['isPrivate'] = this.isPrivate;
    data['indexPending'] = this.indexPending;
    data['dateLastIndexed'] = this.dateLastIndexed ? this.dateLastIndexed.toISOString() : <any>undefined;
    data['updatedByUserId'] = this.updatedByUserId;
    data['isDeleted'] = this.isDeleted;
    data['deletedByUserId'] = this.deletedByUserId;
    data['dateDeleted'] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
    data['cleanedUp'] = this.cleanedUp;
    data['storeFilename'] = this.storeFilename;
    data['originalFilename'] = this.originalFilename;
    if (Array.isArray(this.tags)) {
      data['tags'] = [];
      for (const item of this.tags)
        data['tags'].push(item);
    }
    return data;
  }
}

export interface IFileMetadataBase {
  fileId?: string;
  partitionKey?: string | undefined;
  metadataType?: MetadataType;
  fileExtensionType?: string | undefined;
  mimeType?: string | undefined;
  fileSize?: number;
  contentType?: string | undefined;
  abstract?: string | undefined;
  dateCreated?: Date;
  dateUpdated?: Date;
  createdByPersonId?: string | undefined;
  isTemporary?: boolean;
  isPrivate?: boolean;
  indexPending?: boolean;
  dateLastIndexed?: Date | undefined;
  updatedByUserId?: string | undefined;
  isDeleted?: boolean;
  deletedByUserId?: string | undefined;
  dateDeleted?: Date | undefined;
  cleanedUp?: boolean;
  storeFilename?: string | undefined;
  originalFilename?: string | undefined;
  tags?: string[] | undefined;
}
