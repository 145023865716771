import {FileMetadataBase, IFileMetadataBase} from './FileMetadataBase';

export class MessageFileMetadata extends FileMetadataBase implements IMessageFileMetadata {
  ownerId?: string | undefined;
  threadId?: string | undefined;
  eventId?: string;
  filePath?: string | undefined;

  constructor(data?: IMessageFileMetadata) {
    super(data);
  }

  init(_data?: any) {
    super.init(_data);
    if (_data) {
      this.ownerId = _data['ownerId'];
      this.threadId = _data['threadId'];
      this.eventId = _data['eventId'];
      this.filePath = _data['filePath'];
    }
  }

  static fromJS(data: any): MessageFileMetadata {
    data = typeof data === 'object' ? data : {};
    const result = new MessageFileMetadata();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['ownerId'] = this.ownerId;
    data['threadId'] = this.threadId;
    data['eventId'] = this.eventId;
    data['filePath'] = this.filePath;
    super.toJSON(data);
    return data;
  }
}

export interface IMessageFileMetadata extends IFileMetadataBase {
  ownerId?: string | undefined;
  threadId?: string | undefined;
  eventId?: string;
  filePath?: string | undefined;
}