import React from 'react';
import {useAppDispatch, useAuthUser} from '../../../utility/AppHooks';
import clsx from 'clsx';
import {makeStyles} from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {Fonts} from '../../../../shared/constants/AppEnums';
import {AuthUser} from '../../../../types/models/AuthUser';
import {authLogOut} from '../../../../redux/auth/Actions';
import {useMsal} from '@azure/msal-react';
import {Theme} from '@mui/material';
import {orange} from '@mui/material/colors';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useNavigate} from 'react-router-dom';
import {participantPaths} from '../../../../modules/participantProfile/paths';
import {sessionPaths} from '../../../../modules/sessions/paths';


const useStyles = makeStyles((theme: Theme) => {
    return {
        crUserInfo: {
            paddingTop: 9,
            paddingBottom: 9,
            minHeight: 56,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                paddingTop: 10,
                paddingBottom: 10,
                minHeight: 70,
            },
        },
        profilePic: {
            fontSize: 24,
            backgroundColor: orange[500],
        },
        userInfo: {
            width: 'calc(100% - 75px)',
        },
        userName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
        },
        designation: {
            marginTop: -2,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: theme.palette.text.secondary,
        },
        pointer: {
            cursor: 'pointer',
        },
    };
});

const ProfileOptions: React.FC = () => {
    const dispatch = useAppDispatch();
    const user: AuthUser | undefined = useAuthUser();
    const navigate = useNavigate();

    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleViewProfileClick = () => {
        navigate(participantPaths.ParticipantProfile);
    }

    const handleViewSessionsClick = () => {
        navigate(sessionPaths.SessionSearch);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (event: any) => {
        dispatch(authLogOut());
        instance.logoutRedirect();
    }

    const getUserAvatar = () => {
        if (user && user.displayName) {
            return user.displayName.charAt(0).toUpperCase();
        }
        if (user && user.email) {
            return user.email.charAt(0).toUpperCase();
        }
    };

    const classes = useStyles();

    return (
        <Box
            px={{xs: 4, xl: 7}}
            className={clsx(classes.crUserInfo, 'cr-user-info')}>
            <Box display='flex' alignItems='center'>
                {user && user.photoUrl ? (
                    <Avatar className={classes.profilePic} src={user.photoUrl} />
                ) : (
                    <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
                )}
                <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'>
                        <Box mb={0} className={clsx(classes.userName)}>
                            {user && user.firstName} { user && user.lastName}
                        </Box>
                        <Box
                            ml={3}
                            className={classes.pointer}
                            color={'#313541'}>
                            <ExpandMoreIcon onClick={handleClick} />
                            <Menu
                                id='simple-menu'
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}>
                                <MenuItem onClick={handleViewProfileClick}>
                                    My profile
                                </MenuItem>
                                <MenuItem onClick={handleViewSessionsClick}>
                                    My sessions
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileOptions;