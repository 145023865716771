import React from 'react';
import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';

const errorSectionBreadcrumbs = [new BreadcrumbItemProps('Errors')];

const Error500Page = React.lazy(() => import('./Error500'));
const MaintenancePage = React.lazy(() => import('./Maintenance'));

const errorPagesConfig = [
  { path: 'error-500', element: <Error500Page />, handle: AppRouteData.create('500', undefined, errorSectionBreadcrumbs) } ,
  { path: 'maintenance', element: <MaintenancePage />, handle: AppRouteData.create('Maintenance', undefined, errorSectionBreadcrumbs) } ,
];

export default errorPagesConfig;
