import {AppState} from '../store';
import { createSelector } from '@reduxjs/toolkit';
import {hasItems} from '../../@template/helpers/arrays';
import {Country, ICountry} from '../../types/models/Country';

const selectSettings = (state: AppState) => state.settings;

export const selectFullCountries = (state: AppState) => selectSettings(state).countries;
/*
This has been done to improve performance on the form. Locality lists
are very long in some cases and the constant rendering is clunky without
 */
export const selectCountries = createSelector(
    selectFullCountries,
    (state) => {
        if (hasItems(state)) {
            return state.map((x: ICountry) => {
                const minimalCountry = new Country(x);
                minimalCountry.states = [];
                return minimalCountry;
            });
        }
        return [];
    }
);

export const selectCountryCodeParam = (_: any, countryCode: string) => countryCode;
export const selectCountryStates = createSelector(
    selectFullCountries,
    selectCountryCodeParam,
    (state, countryCode) => {
        const country = state.find((x: ICountry) => x.countryCode === countryCode);
        return country ? country.states : [];
    }
);

export const selectCountriesLoading = createSelector(
    selectSettings,
    (state) => state.countriesLoading
);


export const setSettingsInitialized = createSelector(
    selectSettings,
    (state) => state.settingsInitialized
)

export const selectSettingsLoading = createSelector(
    selectSettings,
    (state) => state.settingsLoading
);

export const selectAgeRanges = createSelector(
    selectSettings,
    (state) => state.ageRanges
);

export const selectError = createSelector(
    selectSettings,
    (state) => state.settingsError
);

export const selectFileTags = createSelector(
    selectSettings,
    (subState) => subState.fileTags
);
export const selectFileSystemTags = createSelector(
    selectSettings,
    (subState) => subState.fileSystemTags
);
export const selectAllFileTags = createSelector(
    selectSettings,
    (subState) => [...subState.fileSystemTags, ...subState.fileTags]
);
