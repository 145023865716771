import React from 'react';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import {grey} from '@mui/material/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import {sessionPaths} from '../../sessions/paths';

const useStyles = makeStyles(() => {
  return {
    button: {
      fontWeight: Fonts.BOLD,
      fontSize: 16,
      textTransform: 'capitalize',
    },
    image: {
      width: '100%',
    },
  };
});

const Error500 = () => {
  const navigate = useNavigate();

  const onGoBackToHome = () => {
    navigate(sessionPaths.SessionSearch);
  };

  const classes = useStyles();

  return (
      <Box
        py={{xl: 8}}
        flex={1}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'>
        <Box
          mb={{xs: 4, xl: 8}}
          maxWidth={{xs: 200, sm: 300, xl: 624}}
          width='100%'>
          <img
            className={classes.image}
            src={'/assets/images/errorPageImages/500.png'}
            alt='500'
          />
        </Box>
        <Box mb={{xs: 4, xl: 5}}>
          <Box
            mb={{xs: 3, xl: 10}}
            fontSize={{xs: 20, md: 24}}
            fontWeight={Fonts.BOLD}>
            Unexpected error
          </Box>
          <Box
            mb={{xs: 4, xl: 10}}
            color={grey[600]}
            fontSize={16}
            fontWeight={Fonts.MEDIUM}>
            <Typography>
              Unexpected error
            </Typography>
            <Typography>
              Something unexpected went wrong. If problem persists, contact support.
            </Typography>
          </Box>
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={onGoBackToHome}>
            Return Home
          </Button>
        </Box>
      </Box>
  );
};

export default Error500;