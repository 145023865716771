import React from 'react';
import {authPaths} from './paths';
import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';

const authSectionBreadcrumbs = [new BreadcrumbItemProps('Home', authPaths.SignIn)];

const SignInPage = React.lazy(() => import('./SignIn'));
const Error404Page = React.lazy(() => import('../errorPages/Error404/index'));

const authRouteConfig = [
    { index: true, element: <SignInPage />, handle: AppRouteData.create('Sign in', undefined, authSectionBreadcrumbs) } ,
    { path: authPaths.Error404, element: <Error404Page />, handle: AppRouteData.create('Error 404', undefined, authSectionBreadcrumbs) } ,
];

export default authRouteConfig;
