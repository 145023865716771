import React, {useContext} from 'react';

import AppContext from '../../utility/AppContext';
import useStyles from '../../../shared/jss/common/common.style';
import AppContextPropsType from '../../../types/AppContextPropsType';
import AuthRoutes from '../../utility/AuthRoutes';
import SinglePageLayout from './SinglePageLayout';



const OperatorLayout: React.FC = () => {
  useStyles();
  const {navStyle} = useContext<AppContextPropsType>(AppContext);

  // return <AppLayout />;
  return <AuthRoutes>
    <SinglePageLayout />
  </AuthRoutes>;
};

export default React.memo(OperatorLayout);
